$(function() {
/* ----------------
	フローティングバナー非表示
	閉じるボタンクリック時に非表示
	----------------- */
	$('#js-top_floating-close').on('click', function() {
		$('.top_floating').css('display', 'none');
		$('#bedore-webagent-inner').addClass('top_floating-close');
		$('.totop').removeClass('top_floating-open');
		$('.footer').removeClass('top_floating-open');
	});


	// $('#totop').removeClass('totop');
	// $('.totop').css({'cssText': 'bottom: 100px;'});
});

$(function() {
	var useragent = window.navigator.userAgent.toLowerCase();
	var ban_wrapper = $('.top_floating--contents');
	var and_link_wrapper = $('.and_link_wrapper');
	var iphone_link_wrapper = $('.iphone_link_wrapper');
	var and_link = $('.and_link');
	var iphone_link = $('.iphone_link');
	var and_link_ban = $('.and_link_ban');
	var iphone_link_ban_2 = $('.iphone_link_ban_2');

	//Android Mobile or tablet
	if (useragent.indexOf('android') != -1) {
		ban_wrapper.css('display', 'block');
		iphone_link.remove();
		$('.top_floating').find('.pc-only').css('display', 'none');
		$('.top_floating').find('.sp-only').css({'cssText': 'display: block !important;'});
		if (!(and_link.length)) {
			iphone_link_wrapper.parents('.top_floating').remove();
		}
	}
	//iPhone or iPad
	else if (useragent.indexOf('iphone') != -1 || useragent.indexOf('ipad') != -1) {
		ban_wrapper.css('display', 'block');
		and_link.remove();
		$('.top_floating').find('.pc-only').css('display', 'none');
		$('.top_floating').find('.sp-only').css({'cssText': 'display: block !important;'});
		if (!(iphone_link.length)) {
			and_link_wrapper.parents('.top_floating').remove();
		}
	} else if (window.outerWidth <= 768) { //768
		ban_wrapper.css('display', 'block');
		and_link.remove();
		$('.top_floating').find('.pc-only').css('display', 'none');
		$('.top_floating').find('.sp-only').css({'cssText': 'display: block !important;'});
		if (!(iphone_link.length)) {
			and_link_wrapper.parents('.top_floating').remove();
		}
	}
	$(window).resize(function() {
		if (useragent.indexOf('android') != -1 || useragent.indexOf('iphone') != -1 || useragent.indexOf('ipad') != -1) {
			return;
		}
		if ((window.outerWidth < 768)) {
			ban_wrapper.css('display', 'block');
			and_link.remove();
			$('.top_floating').find('.pc-only').css('display', 'none');
			$('.top_floating').find('.sp-only').css({'cssText': 'display: block !important;'});
			if (!(iphone_link.length)) {
				and_link_wrapper.parents('.top_floating').remove();
			}
		} else {
			ban_wrapper.css('display', 'block');
			and_link.remove();
			// iphone_link.remove();
			$('.top_floating').find('.pc-only').css('display', 'block');
			$('.top_floating').find('.sp-only').css({'cssText': 'display: none;'});
			if (!(iphone_link.length)) {
				and_link_wrapper.parents('.top_floating').remove();
			}
		}
	});
});

